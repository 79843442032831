import { isTrue } from 'ramda-adjunct'
import { MutableRefObject, useEffect } from 'react'

import { useGetUserProfile } from '../../../lib/queries/useGetUserProfile'
import { requestAccess } from '../../../services/users'
import { UseRequestAccess, UseRequestAccessForUser } from './types'

export const useOnClickOutside = (ref: MutableRefObject<any>, handler: any): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      // Do nothing if clicking ref's element or descendent elements
      if (ref.current == null || isTrue(ref.current.contains(event.target))) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const useRequestAccess = (): UseRequestAccess => {
  const { isLoading, error, data: user } = useGetUserProfile()

  return {
    error,
    isLoading,
    user,
  }
}

export const useRequestAccessForUser = async ({
  setIsLoading,
  setIsToastOpen,
  setToastVariant,
}: UseRequestAccessForUser): Promise<void> => {
  if (setIsLoading != null && setToastVariant != null) {
    try {
      setIsLoading(true)
      await requestAccess()
      setIsLoading(false)
      setIsToastOpen(true)
      setToastVariant('Message')
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setIsToastOpen(true)
      setToastVariant('Alarm')
    } finally {
      setIsLoading(false)
    }
  }
}
