import React from 'react'
import { useGetUserProfile } from 'src/lib/queries/useGetUserProfile'

import { DashboardOrderIndicationsRow } from './components/OrderIndications/DashboardOrderIndicationsRow'
import { useQueryBankerDashboard } from './components/OrderIndications/hooks'
import { NoLiveDeals } from './components/OrderIndications/NoLiveDeals'
import { RequestAccess } from './components/OrderIndications/RequestAccess'

const DashboardOrderIndications: React.FC = () => {
  const { data } = useQueryBankerDashboard()
  const { data: user } = useGetUserProfile()

  if (user === undefined) return <></>

  if (!user.isIoiEnabled) return <RequestAccess user={user} />

  if (data === undefined) return <></>

  if (data.length === 0) return <NoLiveDeals />

  return (
    <div className="ioi-flex-1 ioi-overflow-y-auto ioi-p-2.5">
      {data.map((dashboardOrders) => (
        <DashboardOrderIndicationsRow
          key={dashboardOrders.dealId}
          dashboardOrders={dashboardOrders}
        />
      ))}
    </div>
  )
}

export default DashboardOrderIndications
