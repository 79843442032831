import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import DashboardBlock from './DashboardBlock'

export const DashboardBlockComponent: React.FC = () => {
  const queryClient = new QueryClient()
  return (
    <div className="ioi">
      <QueryClientProvider client={queryClient}>
        <DashboardBlock />
      </QueryClientProvider>
    </div>
  )
}

export default DashboardBlockComponent
