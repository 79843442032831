export const textForRequestAccess = {
  investor: {
    reason: 'submit order indications',
    company: 'Primary Portal',
    action: 'register you',
  },
  others: {
    reason: 'view order indications',
    company: 'Primary Portal',
    action: 'permission you',
  },
}
