import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { navigateToUrl } from '@pp/utils'
import React from 'react'

const Header: React.FC = () => {
  return (
    <div
      className="ioi-w-full ioi-h-[52px] ioi-bg-light_gray ioi-flex ioi-items-center ioi-justify-between ioi-px-2.5"
      data-qa="ioi.dashboard.order-indications.header"
    >
      <div className="ioi-text-2xl" data-qa="ioi.dashboard.order-indications.header-text">
        Order Indications
      </div>
      <div
        onClick={() => navigateToUrl(`/ioi/deals`)}
        data-qa="ioi.dashboard.order-indications.header-link"
      >
        <ArrowTopRightOnSquareIcon className="ioi-h-5 ioi-w-5 ioi-cursor-pointer" />
      </div>
    </div>
  )
}

export default Header
