import { Button } from '@ppui/ui-components'
import React from 'react'

import { useAuth } from '../../../../../contexts'

const StepTwo: React.FC = (): JSX.Element => {
  const { env } = useAuth()
  return (
    <>
      <div
        data-qa="ioi.request-access.step-two.request-submitted-message"
        className="ioi-not-italic ioi-font-medium ioi-text-lg ioi-leading-7 ioi-text-center
         ioi-text-dark-blue ioi-border ioi-border-dark-blue ioi-w-10/12 p-4 ioi-rounded-lg
         ioi-mx-auto"
      >
        Your request has been submitted and
        <br /> the team will be in touch shortly
      </div>
      <div className="ioi-col-span-3 ioi-flex ioi-justify-center ioi-pt-4 ioi-pb-6 ioi-px-6">
        <a href={`https://${env}/ecm/dashboard`}>
          <Button theme="primary" label="Back to dashboard" shadow size="big" type="button" />
        </a>
      </div>
    </>
  )
}

export default React.memo(StepTwo)
