import React from 'react'
import RequestAccessContainer from 'src/routes/Investor/RequestAccess/components/RequestAccessContainer'
import { ProfileDTO } from 'src/services/users'

export const RequestAccess: React.FC<{ user: ProfileDTO }> = ({ user }) => {
  return (
    <div className="ioi-h-full ioi-flex ioi-items-center ioi-justify-center">
      <RequestAccessContainer accountType={user.accountType} />
    </div>
  )
}
