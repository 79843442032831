import { Toast as PpuiToast } from '@ppui/ui-components'
import { AlertType } from '@ppui/ui-components/dist/cjs/components/Alert/types'
import { isTrue } from 'ramda-adjunct'
import React, { useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { useOnClickOutside } from '../../routes/Investor/RequestAccess/hooks'

interface ToastProps {
  variant: AlertType
  mainText: string
  additionalText?: string
  autoHideDurationInSeconds?: number
  onClose: () => void
  isBackgroundBlur?: boolean
}

const Toast: React.FC<ToastProps> = ({
  variant,
  mainText,
  additionalText = '',
  autoHideDurationInSeconds = 3,
  onClose,
  isBackgroundBlur,
}): JSX.Element => {
  const [toastTimeOut, setToastTimeOut] = useState<ReturnType<typeof setTimeout>>()
  const ref = useRef<HTMLDivElement>(null)
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => onClose())
  useEffectOnce(() => {
    if (variant !== 'Alarm') {
      const temporaryToastTimeOut = setTimeout(function () {
        onClose()
      }, autoHideDurationInSeconds * 1000)
      setToastTimeOut(temporaryToastTimeOut)
    }
  })

  const toastClose = (): void => {
    onClose()
    if (toastTimeOut === undefined) {
      return
    }
    clearTimeout(toastTimeOut)
  }

  return (
    <div
      className="ioi-relative ioi-z-[1500] ioi-h-full"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {isTrue(isBackgroundBlur) && (
        <div
          className="ioi-fixed ioi-inset-0 ioi-bg-gray-500 ioi-bg-opacity-75 ioi-transition-opacity"
          data-qa="ioi.toast.modal-background"
        />
      )}
      <div
        className="ioi-w-80 ioi-fixed ioi-top-20 ioi-right-4"
        data-qa="ioi.toast.container"
        ref={ref}
      >
        <PpuiToast
          type={variant}
          title={mainText}
          onCloseClick={toastClose}
          message={additionalText}
        />
      </div>
    </div>
  )
}
export default React.memo(Toast)
