import {
  BookOpenIcon,
  BuildingLibraryIcon,
  CircleStackIcon,
  Cog8ToothIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline'
import { anyPass, equals } from 'ramda'
import React from 'react'
import { AccountType } from 'src/lib/types'
import { isFeatureFlagEnabled } from 'src/lib/utils/core'

import { FEATURES } from '../../constants/features'
import { INVESTOR_USER_GUIDE_URL } from '../../constants/urls'
import { FeatureFlagsDto } from '../../lib/queries/useGetFeatureFlags'

interface LinkItem {
  icon: JSX.Element
  url: string
  text: string
  dataQa: string
  isAvailable: boolean
}

export const getSecondaryNavLinks = (
  accountType: AccountType,
  currentFeatureFlags?: FeatureFlagsDto[]
): LinkItem[] => {
  return [
    {
      icon: <CursorArrowRaysIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/order-indications',
      text: 'Order indications overview',
      dataQa: 'ioi.secondary-header.order-indications',
      isAvailable: equals(accountType, AccountType.INVESTOR),
    },
    {
      icon: <CircleStackIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/desk-management',
      text: 'Desks',
      dataQa: 'ioi.investor.secondary-header.desk-management',
      isAvailable: equals(accountType, AccountType.INVESTOR),
    },
    {
      icon: <Cog8ToothIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/preferences',
      text: 'Preferences',
      dataQa: 'ioi.secondary-header.preferences',
      isAvailable: equals(accountType, AccountType.INVESTOR),
    },
    {
      icon: <CursorArrowRaysIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/deals',
      text: 'Indication overview',
      dataQa: 'ioi.secondary-header.deals',
      isAvailable: anyPass([equals(AccountType.BANKER), equals(AccountType.SELL_SIDE_SALES)])(
        accountType
      ),
    },
    {
      icon: <BookOpenIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: INVESTOR_USER_GUIDE_URL,
      text: 'User Guide',
      dataQa: 'ioi.secondary-header.user-guide',
      isAvailable:
        equals(accountType, AccountType.INVESTOR) &&
        equals(isFeatureFlagEnabled(FEATURES.ENABLE_USER_GUIDE, currentFeatureFlags), true),
    },
    {
      icon: <EnvelopeIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/admin/sales-trader-emails',
      text: 'ST emails',
      dataQa: 'ioi.admin.secondary-header.st-emails',
      isAvailable: equals(accountType, AccountType.PRIMARY_PORTAL_AGENT),
    },
    {
      icon: <BuildingLibraryIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/admin/organizations',
      text: 'IOI - Organizations',
      dataQa: 'ioi.admin.secondary-header.organizations',
      isAvailable: equals(accountType, AccountType.PRIMARY_PORTAL_AGENT),
    },
    {
      icon: <CircleStackIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/admin/desks',
      text: 'Desks',
      dataQa: 'ioi.admin.secondary-header.desks',
      isAvailable: equals(accountType, AccountType.PRIMARY_PORTAL_AGENT),
    },
    {
      icon: <CircleStackIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/admin/users',
      text: 'IOI - Users',
      dataQa: 'ioi.admin.secondary-header.users',
      isAvailable: equals(accountType, AccountType.PRIMARY_PORTAL_AGENT),
    },
    {
      icon: <BuildingLibraryIcon className="ioi-mt-0.5 ioi-w-4 ioi-h-4" />,
      url: '/admin/escape-hatch',
      text: 'IOI - Escape Hatch',
      dataQa: 'ioi.admin.secondary-header.escape-hatch',
      isAvailable: equals(accountType, AccountType.PRIMARY_PORTAL_AGENT),
    },
  ]
}
