import { navigateToUrl } from '@pp/utils'
import React from 'react'
import { DashboardOrders } from 'src/routes/Banker/OrderIndications/types'

export const DashboardOrderIndicationsRow: React.FC<{ dashboardOrders: DashboardOrders }> = ({
  dashboardOrders,
}) => {
  const { issuerName, numberOfOrderIndications, dealId } = dashboardOrders
  const indicationText = numberOfOrderIndications === 1 ? 'indication' : 'indications'
  const maxLength = 30
  const truncatedIssuerName =
    issuerName.length > maxLength ? `${issuerName.substring(0, maxLength - 3)}...` : issuerName

  return (
    <div
      className="ioi-flex ioi-justify-between ioi-py-1"
      data-qa={`ioi.dashboard.order-indications.row.${dealId}`}
    >
      <span
        onClick={() => navigateToUrl(`/ioi/deals/order-indications?dealId=${dealId}`)}
        className="ioi-cursor-pointer"
        data-qa={`ioi.dashboard.order-indications.row.issuerName.${dealId}`}
      >
        {truncatedIssuerName}
      </span>
      <span
        className="ioi-text-xs ioi-text-gray-600 ioi-rounded-full ioi-border ioi-border-gray-300 ioi-bg-gray-100 ioi-px-1.5"
        data-qa={`ioi.dashboard.order-indications.row.numberOfOrderIndications.${dealId}`}
      >
        {`${numberOfOrderIndications} ${indicationText}`}
      </span>
    </div>
  )
}
