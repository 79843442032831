export const FEATURES = {
  ENABLE_SELF_ACKNOWLEDGE_BUTTON_IOI: 'ioi-enable_self_acknowledge_button-prod-20221013',
  ENABLE_ALLOCATION: 'ioi-enable-allocation-20221125',
  ENABLE_COLUMN_RESIZING: 'ioi_enable_automatic_column_resizing-20240216',
  ENABLE_USER_GUIDE: 'ioi-enable-user-guide-20240404',
  ENABLE_SUPPRESS_EMAILS_FOR_ALL_DEALS_TOGGLE: 'ioi-suppress-emails-for-all-deals-07052024',
  ENABLE_SUPPRESS_EMAILS_ANNOUNCED_DEALS: 'ioi-enable-suppress-email-announced-deals-13052024',
  ENABLE_BANKER_ALLOCATION_COLUMNS: 'ioi-enable-banker-allocations-columns-22072024',
  ENABLE_INVESTOR_ALLOCATION_COLUMNS: 'ioi-enable-investor-allocations-columns-19082024',
  ENABLE_BANKER_DASHBOARD_BLOCK: 'ioi-enable-banker-dashboard-indication-block_05092024',
}
