import { TableNoDataMessage } from '@ppui/ui-components'
import React from 'react'

export const NoLiveDeals: React.FC = () => {
  return (
    <div className="ioi-h-full ioi-flex ioi-items-center ioi-justify-center">
      <TableNoDataMessage dataQaSuffix="ioi">No Live Deals</TableNoDataMessage>
    </div>
  )
}
