import { API } from './aws-api'

export async function postApplicationNotEnabledNotification(pathname: string): Promise<void> {
  void (await API().post({
    path: '/v1/ms-teams/application-not-enabled',
    init: {
      body: {
        requestedFrom: pathname,
      },
    },
  }))
}
