import React from 'react'
import { FEATURES } from 'src/constants/features'
import { useGetFeatureFlags } from 'src/lib/queries/useGetFeatureFlags'
import { isFeatureFlagEnabled } from 'src/lib/utils/core'

import Header from './components/Header/Header'
import DashboardOrderIndications from './OrderIndications'

const DashboardBlock: React.FC = () => {
  const { data } = useGetFeatureFlags()
  const isEnabled = isFeatureFlagEnabled(FEATURES.ENABLE_BANKER_DASHBOARD_BLOCK, data)

  if (!isEnabled) return <></>

  return (
    <div className="ioi-h-[783px] ioi-min-h-full ioi-flex ioi-flex-col ioi-w-[343px] ioi-bg-white ioi-rounded-[12px]">
      <Header />
      <div className="ioi-flex-1 ioi-overflow-y-auto">
        <DashboardOrderIndications />
      </div>
    </div>
  )
}

export default DashboardBlock
