import { Button, LoadingScreen } from '@ppui/ui-components'
import { type AlertType } from '@ppui/ui-components/dist/cjs/components/Alert/types'
import Toast from 'components/Toast/Toast'
import React, { useState } from 'react'

import { useRequestAccessForUser } from '../../hooks'
import { type StepOneProps } from '../../types'

const StepOne: React.FC<StepOneProps> = ({ next }): JSX.Element => {
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [toastVariant, setToastVariant] = useState<AlertType | null>(null)

  const useHandleRequestAccess = (): void => {
    useRequestAccessForUser({
      setIsLoading,
      setIsToastOpen,
      setToastVariant,
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <>
      <div
        className="ioi-not-italic ioi-font-medium ioi-text-lg ioi-leading-7 ioi-text-center
         ioi-text-dark-blue ioi-w-10/12 ioi-p-4 ioi-mx-auto"
      >
        The team will get in touch right away
      </div>
      <div className="ioi-col-span-3 ioi-flex ioi-justify-center ioi-pt-4 ioi-pb-6 ioi-px-6">
        {isLoading && <LoadingScreen />}
        <Button
          theme="primary"
          label="Request access"
          shadow
          size="normal"
          type="button"
          onClick={useHandleRequestAccess}
          dataQa="ioi.request-access-page.request-access-btn"
        />
      </div>
      {isToastOpen && toastVariant !== null && (
        <Toast
          mainText={
            toastVariant === 'Message' ? 'Your request has been submitted' : 'Error requesting'
          }
          onClose={() => {
            setIsToastOpen(!isToastOpen)
            next()
          }}
          variant={toastVariant}
          autoHideDurationInSeconds={2}
        />
      )}
    </>
  )
}

export default React.memo(StepOne)
