import React, { useCallback, useMemo, useState } from 'react'
import { AccountType } from 'src/lib/types'

import { StepsInterface } from '../types'
import { textForRequestAccess } from './const'
import StepOne from './RequestAccessSteps/StepOne'
import StepTwo from './RequestAccessSteps/StepTwo'

const RequestAccessContainer = ({ accountType }: { accountType: AccountType }): JSX.Element => {
  const [current, setCurrent] = useState<number>(0)

  const next = useCallback((): void => {
    setCurrent(current + 1)
  }, [current])

  const steps: StepsInterface[] = useMemo(
    () => [
      {
        title: 'First',
        content: <StepOne next={next} />,
      },
      {
        title: 'Last',
        content: <StepTwo />,
      },
    ],
    [next]
  )

  const content =
    accountType === AccountType.INVESTOR
      ? textForRequestAccess.investor
      : textForRequestAccess.others

  return (
    <div className="ioi-grow-[0]">
      <div
        className="ioi-font-normal ioi-not-italic ioi-text-2xl 2xl:ioi-text-3xl ioi-leading-9
         ioi-text-center ioi-text-dark-blue ioi-pt-11 ioi-pb-6 ioi-px-6"
      >
        <span>
          To <b>{content.reason}</b>
          <br />
          via <b>{content.company},</b>
          <br />
          we need to <b>{content.action}</b>
        </span>
      </div>
      <>{steps[current].content}</>
    </div>
  )
}

export default React.memo(RequestAccessContainer)
