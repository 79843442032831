import { useQuery, UseQueryResult } from 'react-query'
import { NON_CACHED_QUERY_PROPS } from 'src/constants/queries'
import { ApiError, ReactQueryKey } from 'src/lib/types'
import { DashboardOrders } from 'src/routes/Banker/OrderIndications/types'
import { getBankerDashboardOrders } from 'src/services/banker-orders'

export const useQueryBankerDashboard = (): UseQueryResult<DashboardOrders[], ApiError> => {
  return useQuery([ReactQueryKey.BANKER_DASHBOARD], async () => await getBankerDashboardOrders(), {
    ...NON_CACHED_QUERY_PROPS,
    cacheTime: 0,
    notifyOnChangeProps: ['data', 'error'],
    refetchInterval: 3000,
  })
}
