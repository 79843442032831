import { OrderHistoryDetails } from 'components/Orders/OrderHistoryDetails/types'
import {
  BankerOrderIndicationsDto,
  DashboardOrders,
} from 'src/routes/Banker/OrderIndications/types'

import { API } from './aws-api'

export async function getBankerOrdersByDealId(dealId: string): Promise<BankerOrderIndicationsDto> {
  return await API<BankerOrderIndicationsDto>().get({
    path: `/bank/v1/deals/${dealId}/orders`,
  })
}

export async function postBankerOrderAcknowledge(orderId: string): Promise<void> {
  await API().post({
    path: `/bank/v1/orders/${orderId}/acknowledge`,
  })
}

export async function postBankerOrderDecline(orderId: string): Promise<void> {
  await API().post({
    path: `/bank/v1/orders/${orderId}/decline`,
  })
}

export async function getOrderHistoryByDealId(orderId: string): Promise<OrderHistoryDetails[]> {
  return await API<OrderHistoryDetails[]>().get({
    path: `/v1/order-history/order-indications/${orderId}`,
  })
}

export async function getOrderHistoryCSVByDealId(orderId: string): Promise<string> {
  return await API<string>().get({
    path: `/v1/order-history/csv/order-indications/${orderId}`,
  })
}

export async function downloadOrdersByDealCSV(dealId: string): Promise<Blob> {
  const csv = await API<string>().get({
    init: { headers: { Accept: 'application/CSV' } },
    path: `/bank/v1/deals/${dealId}/orders`,
  })

  const blob = new Blob([csv], { type: 'application/CSV;charset=utf-8' })
  return blob
}

export async function getBankerDashboardOrders(): Promise<DashboardOrders> {
  return await API<DashboardOrders>().get({
    path: `/bank/v1/orders/dashboard`,
  })
}
